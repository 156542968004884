import React, { useState, useEffect } from 'react';
import './dispute.css'
import { useLocation, useNavigate, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Dashboard/SideBar/sidebar';
import MenuBar from '../Dashboard/Menubar/menubar';
import { useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { getDisputeData } from '../../redux/actions/dashboard_action';
import Loader from '../loader/loader';
import DisputeCreditComponent from './component/dispute-credit-component';

const AxisBank = require("../../images/Bank/Bank.png");


const Dispute = (props) => {
    const [creditReportData, setCreditReportData] = useState("");
    const [selectedBureau, setSelectedBureau] = useState('');
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        } else {
            setLoader(true);
            setSelectedBureau('EXPERIAN');
            getCreditReport('EXPERIAN');
        }
    }, []);



    const CreateDispute = (props) => {
        navigate('/createdispute', { state: props })
    }

    const DisputeHistory = () => {
        navigate('/disputehistory')
    }

    const getCreditReport = (bureau = 'EXPERIAN') => {
        getDisputeData(bureau).then((resp) => {
            setCreditReportData(resp.data);
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
        })
    }

    const accountDetails = (accountess) => {
        var accounts = [];
        for (let index = 0; index < accountess.length; index++) {
            accounts.push(
                <div className="accordion-item dispute-accordion my-3" key={index + "Account"}>
                    <div className="accordion-header accordion-button align-items-start pb-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="false" aria-controls={"collapseOne" + index}>
                        <div className='row w-100 align-items-start g-2 g-sm-0'>
                            <div className='col-12 col-sm-4'>
                                <div className='d-flex align-items-start'>
                                    <div className='bank-image-box'>
                                        <img src={AxisBank} width={100}></img>
                                    </div>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>{accountess[index].creditAccountInformation.lender ?? "-"}</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{accountess[index].creditAccountInformation.accountType ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-3'>
                                <div className='d-flex align-items-start'>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>Account Number</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className='reported-date'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-3'>
                                <div className='d-flex align-items-start'>
                                    <div className='text-start ms-3'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mb-1'>
                                                <h1 className='bank-name-text mb-0'>Account Status</h1>
                                            </div>
                                        </div>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col-12 mt-1'>
                                                <p className={accountess[index].creditAccountDescription.accountStatus === 'CLOSED' ? "dis-account-type account-close" : "dis-account-type account-active"}>{accountess[index].creditAccountDescription.accountStatus ?? "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-sm-2'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    <button className='raise_dispute_btn' onClick={() => { CreateDispute(accountess[index]) }}>Raise a Dispute</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return accounts;
    }

    return (
        <>
            {loader && <Loader />}
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='disputeDetails' className="sidebat-inner-box sidnav-inner-box">

                    {/* <div className='container-lg container-fluid my-3'>
                        <div className='disptue-section mb-4 py-2'>
                            <div className='col-lg-6 col-md-6 p-0 m-0 ps-2 pt-2 col-sm-12'>
                                <h1 className='dispute-title p-0 m-0'>Credit Report Dispute</h1>
                            </div>
                        </div>
                    </div> */}
                    {/* <DisputeCreditComponent></DisputeCreditComponent> */}
                    {creditReportData && creditReportData?.creditAccountInformationDetails &&
                        <div className='container-lg container-fluid my-3'>
                            <div className='disptue-section mb-4 py-2'>
                                <div className='row p-0 m-0'>
                                    <div className='col-6 p-0 m-0 ps-2 pt-2'>
                                        <h1 className='dispute-title p-0 m-0'>Choose an account to raise a dispute</h1>
                                    </div>
                                    <div className='col-6 p-0 m-0'>
                                        <button className='dispute-history-btn' onClick={() => { DisputeHistory() }}>Dispute History</button>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 id='accountInformationButtons' className="accordion-header accordion-header accordion-button align-items-start pb-0 collapsed" type="button" aria-expanded="true" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-controls="panelsStayOpen-collapseOne">
                                        <div className='w-100'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="acc-info-text" >Your Accounts</p>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                        <div className="accordion-body">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='account-accordion-box'>
                                                        <div className="accordion" id="accordionFlushExample">
                                                            {creditReportData?.creditAccountInformationDetails && accountDetails(creditReportData.creditAccountInformationDetails)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {creditReportData == null &&
                        <div className='text-center boosted-score-text pt-5'><p>No credit dispute report found</p></div>
                    }

                </div>
            </div>
        </>
    )
}

export default Dispute;