import React, { useState, useEffect } from 'react';
import './dispute.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Dashboard/SideBar/sidebar';
import MenuBar from '../Dashboard/Menubar/menubar';
import { getDispute } from '../../redux/actions/dashboard_action';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Loader from '../loader/loader';


const DisputeDetails = (props) => {

    const GoBackImg = require("../../images/assets/backgo.png");
    const AxisBank = require("../../images/Bank/Bank.png");
    const [disputeHistory, setdisputeHistory] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    let inx = 0;
    const [loader, setLoader] = useState(false);
    
    const GoBack = () => {
        navigate('/disputehistory')
    }

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        } else {
            setLoader(true);
            getDispute().then(resp => {
                setdisputeHistory(resp.data);
                setLoader(false);
            }).catch(error => {
                console.log(error)
                setLoader(false);
            })
        }
    }, []);

    return (
        <>
           {loader && <Loader />}
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='disputeDetails' className="sidebat-inner-box sidnav-inner-box">
                    <div className='container-lg container-fluid my-3'>
                        <div className="go-back" onClick={() => { GoBack() }}><img src={GoBackImg} /> Go Back</div>
                        <div className='disptue-section mt-4'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h1 className='dispute-title'>Dispute Details</h1>
                                </div>
                            </div>
                            {state && state.disputeData &&
                                <div className="accordion-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='account-accordion-box'>
                                                <div className="accordion" id="accordionFlushExample">
                                                    <div className="accordion-item dispute-accordion my-3" >
                                                        <div className="accordion-header accordion-button align-items-start pb-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"} aria-expanded="false" aria-controls={"collapseOne"}>
                                                            <div className='row w-100 align-items-start g-2 g-sm-0'>
                                                                <div className='col-12 col-sm-3'>
                                                                    <div className='d-flex align-items-start'>
                                                                        <div className='bank-image-box'>
                                                                            <img src={AxisBank} width={100}></img>
                                                                        </div>
                                                                        <div className='text-start ms-3'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mb-1'>
                                                                                    <h1 className='bank-name-text mb-0'>BANK</h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mt-1'>
                                                                                    <p className='reported-date'>{state.disputeData.data.lender ?? "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 col-sm-3'>
                                                                    <div className='d-flex align-items-start'>
                                                                        <div className='text-start ms-3'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mb-1'>
                                                                                    <h1 className='bank-name-text mb-0'>Dispute Id</h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mt-1'>
                                                                                    <p className='reported-date'>{state.disputeData.disputeId ?? "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-sm-2'>
                                                                    <div className='d-flex align-items-start'>
                                                                        <div className='text-start ms-3'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mb-1'>
                                                                                    <h1 className='bank-name-text mb-0'>Account Number</h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mt-1'>
                                                                                    <p className='reported-date'>{state.disputeData.data.accountNumber ?? "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-sm-2'>
                                                                    <div className='d-flex align-items-start'>
                                                                        <div className='text-start ms-3'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mb-1'>
                                                                                    <h1 className='bank-name-text mb-0'>Mobile Number</h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mt-1'>
                                                                                    <p className='reported-date'>{state.disputeData.data.mobileNumber ?? "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-sm-2'>
                                                                    <div className='d-flex align-items-start'>
                                                                        <div className='text-start ms-3'>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mb-1'>
                                                                                    <h1 className='bank-name-text mb-0'>STATUS</h1>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row d-flex align-items-center'>
                                                                                <div className='col-12 mt-1'>
                                                                                    <p className={"dis-account-type account-active"}>{state.disputeData.disputeStatus ?? "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container table-responsive">
                                                <table className="table table-bordered table-hover">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            {/* <th scope="col">#</th> */}
                                                            <th scope="col">Dispute For</th>
                                                            <th scope="col">Before</th>
                                                            <th scope="col">After</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {state.disputeData.data.creditAccountInformationDetails && Object.entries(state.disputeData.data.creditAccountInformationDetails).map(([fieldkey, fieldvalue]) => (
                                                                
                                                            Object.entries(fieldvalue).map(([keyInfo, value]) => (
                                                                value && value.update != ""
                                                                    ? <tr>
                                                                        {/* <th scope="row">{index + 1}</th> */}
                                                                        <td>{keyInfo}</td>
                                                                        <td>{value.existing}</td>
                                                                        <td>{value.updated}</td>
                                                                    </tr>
                                                                    : null
                                                            ))
                                                        ))}

                                                        {state.disputeData.data.personalInformation && Object.entries(state.disputeData.data.personalInformation).map(([fieldkey, fieldvalue]) => (
                                                            fieldvalue && fieldvalue.update != ""
                                                                ? <tr>
                                                                    {/* <th scope="row">{1}</th> */}
                                                                    <td>{fieldkey}</td>
                                                                    <td>{fieldvalue.existing}</td>
                                                                    <td>{fieldvalue.updated}</td>
                                                                </tr>
                                                                : null
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DisputeDetails;