import AsyncStorage from '@react-native-async-storage/async-storage';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../../App.css';
import { setAuthHeader } from '../../../config/api';
import { loginAction, otpVerification, resendOTPAction } from '../../../redux/actions/auth_action';
import { dispatchAction } from '../../../redux/actions/common_action';
import { enhanceFetchCredit } from '../../../redux/actions/dashboard_action';
import { SIGN_IN_ERROR, SIGN_IN_SUCCESS } from '../../../redux/constants';
import Loader from '../../loader/loader';
import './otp.css';
import Footer from '../../landing-page/Footer/Footer';
import { v4 as uuidv4 } from 'uuid';

const Logo = require("../../../images/assets/Logo.png");

function OTP() {
    const [otp, setOtp] = useState("");
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [userExists, setUserExists] = useState();
    const [resendOtp, setResendOtp] = useState();
    const [isShowMore, setIsShowMore] = useState(false);
    const [isAcceptTerm, setIsAcceptTerm] = useState(false);
    const [experianTems, setExperianTerms] = useState(false);
    const [fillupdetails, setFillupdetails] = useState();
    const [otpReferenceId, setOtpReferenceId] = useState(null);
    const [disableResendBtn, setDisableResendBtn] = useState(false);
    const navigate = useNavigate();
    var uuid = "";
    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        setDisableResendBtn(false)
        if (user?.access_token) {
            navigate('/dashboard');
        } else if (state === null) { }
        if (state !== null) {
            setOtpReferenceId(state.otpReferenceId)
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const verifyOtp = () => {
        setFillupdetails('');
        setUserExists('')
        setShowError(false)
        if (!otp || otp === '' || otp.length < 6) {
            setShowError(true)
        } else if (!isAcceptTerm && state.from !== "login") {
            setFillupdetails("Please accept experian's terms and condition")
        }
        else {
            setLoader(true)
            // User already registered 
            var param = {
                "mobileNumber": state.mobileNumber,
                "otp": otp,
                "tncAccepted": "YES",
                "otpReferenceId": otpReferenceId
            }
            var headerOtp = {
                "Content-Type": "application/json"
            }

            // For New User Registration that time we fetch pull report
            // Pass Header x-user-flow  & reference Id 
            if (state.from === "register") {
                uuid = state?.referenceId;
                headerOtp = {
                    "Content-Type": "application/json",
                    'x-user-flow': 'signUp',
                    'x-reference-id': uuid
                }
                param = {
                    "mobileNumber": state.mobileNumber,
                    "otp": otp,
                    "experianTncAccepted": "YES"
                }
            }
            otpVerification(param, headerOtp).then(resp => {
                if (resp.statusCode === "OTP_VERIFIED") {
                    setLoader(false)
                    AsyncStorage.setItem('token', resp.data.access_token)
                    AsyncStorage.setItem('refreshToken', resp.data.refresh_token)
                    setAuthHeader(resp.data.access_token)
                    dispatch(dispatchAction(SIGN_IN_SUCCESS, resp.data, false));
                    if (state.from === 'login') {
                        navigate('/dashboard')
                    } else {
                        if (state.from === "register") {
                            setLoader(true)
                            enhanceFetchCredit(uuid).then(resp => {
                                // console.log(resp)
                                if (resp?.data && resp?.data.statusCode === "SUCCESS") {
                                    navigate('/dashboard', { state: { "success": resp?.data.statusMessage } });
                                }
                            }).catch(error => {
                                setLoader(false)
                                //ENHANCE_CREDIT_FETCH_FAILED
                                //INTERNAL_SERVER_ERROR
                                navigate('/dashboard', { state: { "error": error?.response.data.errorMessage } });
                            });
                        }
                        //navigate('/emailverification')
                    }
                } else {
                    dispatch(dispatchAction(SIGN_IN_ERROR, resp.data.error, true));
                }
            }).catch(error => {
                setLoader(false)
                dispatch(dispatchAction(SIGN_IN_ERROR, error.message, true));
                setUserExists(error?.response.data.errorMessage)
                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "OTP_VERIFY_ATTEMPTS_EXCEEDED") {
                    //// DISABLE BUTTON ONLY RESEND
                    setDisableResendBtn(true);
                }

            });
        }
    }

    const resendOTPApi = () => {
        setShowError('')
        setOtp('')
        setResendOtp('')
        setUserExists('');
        setDisableResendBtn(false)
        if (state.mobileNumber === undefined || state.mobileNumber === '') {
        } else {
            const param = {
                "mobileNumber": state.mobileNumber,
                "otpReferenceId": otpReferenceId
            };
            setLoader(true)
            resendOTPAction(param).then(resp => {
                setLoader(false)
                if (resp && resp.statusCode === "OTP_SENT") {
                    setSeconds(60);
                    setOtpReferenceId(resp.otpReferenceId)
                }
                //setResendOtp("Resend OTP")
            }).catch(error => {
                setLoader(false)

                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "RESEND_OTP_ATTEMPT_EXCEEDED") {
                    //// DISABLE BUTTON ONLY RESEND
                    setDisableResendBtn(true);
                }

                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode === "FREQUENT_RESEND") {
                    setSeconds(60);
                    setDisableResendBtn(false);
                }

                /// SHOWING GENERAL MSG ON ERROR SCENARIO
                if (error && error?.response && error?.response.data &&
                    error?.response.data.errorCode != "FREQUENT_RESEND") {
                    setUserExists(error?.response.data.errorMessage);
                } else {
                    if (error?.response.data.errorCode != "FREQUENT_RESEND") {
                        setUserExists("Something went wrong!");
                    }
                }
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            verifyOtp()
        }
    };

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const acceptTermConditon = (event) => {
        setFillupdetails('');
        setIsAcceptTerm(event.target.checked);
    }

    const readLessMorestyle = {
        WebkitLineClamp: isShowMore ? "6" : "3",
    };

    const btnEnableDisable = {
        opacity: otp == '' || otp.length !== 6
            ? 0.5 : 1.0,
    };

    const resendOTPDisable = {
        opacity: disableResendBtn ? 0.5 : 1.0,
    };
    return (

        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score..
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0  form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className='text-start '>
                                        OTP Verification
                                    </h1>
                                    {state !== null && (state.mobileNumber !== undefined || state.mobileNumber !== '') && <p className='mt-md-3'>
                                        Enter the OTP you would have received on +91 {state.mobileNumber}
                                    </p>}
                                    <div className='form-input-box otp-box'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className='mb-1 mb-md-2'>
                                                    <label className='label-label'>6 Digit otp </label>
                                                </div>
                                                <div>
                                                    <input type="tel" className='input-box form-control' minLength="6" maxLength="6" autoComplete='off' value={otp}
                                                        pattern="\d{3}-\d{3}-\d{4}"
                                                        onChange={(e) => {
                                                            setOtp(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    {(showError && (!otp || otp === '')) && <span className="demo-tetx">Please enter your OTP</span>}
                                                    {userExists !== '' && <span className='demo-tetx mt-2'>{userExists}</span>}
                                                    {resendOtp !== '' && <p className='demo-tetx mt-1'>{resendOtp}</p>}
                                                </div>
                                            </div>
                                            <div className={'resend-otp-box'}>
                                                {seconds !== 0 && <div className='form-inner-box mb-1 my-mb-1'>
                                                    <div className='text-end text-sm-end my-1 my-md-1'>
                                                        <label className='getotp-text'>
                                                            Resend OTP in 00:{seconds > 9 ? `${seconds}` : `0${seconds}`}
                                                        </label>
                                                    </div>
                                                </div>}
                                                {seconds === 0 && <div className='form-inner-box mb-1 my-mb-1'>
                                                    <div className='text-end text-sm-end my-1 my-md-1'>
                                                        <label className='getotp-text' style={resendOTPDisable}
                                                            onClick={() => {
                                                                if (!disableResendBtn) {
                                                                    resendOTPApi();
                                                                }

                                                            }}>
                                                            Resend OTP
                                                        </label>
                                                    </div>
                                                </div>}
                                            </div>

                                            {state.from === "register" &&
                                                <Fragment>
                                                    <div className="d-flex flex-row align-items-start pt-2">
                                                        <input
                                                            className='otp-checkbox'
                                                            type="checkbox"
                                                            id="flexCheckDefault"
                                                            value={isAcceptTerm}
                                                            onChange={acceptTermConditon}
                                                        />
                                                        <div className='ps-2 pt-0'>
                                                            <label
                                                                className="experian-term-line"
                                                                style={readLessMorestyle}
                                                            >
                                                                I hereby consent to Boost.Money being appointed as my
                                                                authorized representative to receive my Credit Information
                                                                from Experian for the purpose of Credit Assessment to advise
                                                                me on the financial journey, on ongoing basis for not
                                                                exceeding a period of six (6) months.
                                                            </label>
                                                            <label className="term-txt-otp">
                                                                I also agree to{" "}
                                                                <a href="/experian_term">
                                                                    Experian’s Terms and Conditions.
                                                                </a>
                                                            </label>

                                                            <div
                                                                className="read-more pt-1"
                                                                onClick={toggleReadMoreLess}>
                                                                {isShowMore ? "Read less" : "Read more"}
                                                            </div>
                                                        </div>


                                                    </div>
                                                    {(!isShowMore) && <div className="otp-ext-space-read-more"></div>}
                                                    {fillupdetails !== '' && <p className='experian-term-line text-danger mt-0 ps-3 font-weight-bold'>{fillupdetails}</p>}
                                                </Fragment>
                                            }
                                            <div className={(state.from === "login" ? 'mt-30' : 'mt-5 my-1 my-md-1') + ' ' + 'form-inner-box align-items-end otp-button-box'}>
                                                <div className='text-center text-sm-end my-1 my-md-1 mb-4'>
                                                    <button type='button' className='next-button'
                                                        style={btnEnableDisable} onClick={() => {
                                                            verifyOtp();

                                                        }}>Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default OTP;