import httpClient from "../../config/api";

const creditReport = (bureau) =>
  new Promise((resolve, reject) => {
    // if (bureau === "TransUnion") {
    //   bureau = "CIBIL";
    // }
    httpClient({
      method: 'GET',
     // url: 'api/v1/credit/report/get' + (bureau !== '' ? '?bureauName=' + bureau : ''),
      url: 'api/v1/credit/report/get/analysis',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

  const getDisputeData = (bureau) =>
    new Promise((resolve, reject) => {
      httpClient({
        method: 'GET',
        url: 'api/v1/credit/report/get' + (bureau !== '' ? '?bureauName=' + bureau : '')
      })
        .then(resp => {
          resolve(resp.data);
        })
        .catch(error => {
          reject(error);
        });
    });

// const boostScoreReport = () =>
//   new Promise((resolve, reject) => {
//     httpClient({
//       method: 'GET',
//       url: 'api/v1/score/get',
//     })
//       .then(resp => {
//         resolve(resp.data);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });

const boostScoreReport = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/multiple/score/get',
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const scoreByUploadStatement = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      // url: 'api/v1/score/uploadStatement',
      url: 'api/v1/multiple/uploadStatement',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const creditReportService = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/credit/report/upload',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {

        reject(error.response.data);
      });
  });

const uploadGenDocument = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: '/api/v1/upload/file',
      data: data, headers: {
        "Content-Type": "multipart/form-data",
        "x-reference-id": uuid
      },
      onUploadProgress: (progressEvent) => {
        // console.log("onUploadProgress", progressEvent);
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const refreshToken = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/google/oauth/refreshToken/verify',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const reportDownload = (langCode) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      responseType: 'blob',
      url: 'api/v1/boost/report/get?language=' + langCode,
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const downloadExcelReport = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      responseType: 'blob',
      url: 'api/v1/boost/report/get/excel',
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
      reject(error);
    });
  });

const createDisputeData = (data) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/credit/report/dispute',
      data: data, headers: { "Content-Type": "application/json" }
    })
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getDispute = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/user/dispute/get',
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getFipTSPUrl = (uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/fiu/get/url',
      headers: {
        "x-reference-id": uuid
      }

    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getFiuStatus = (data, uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/fiu/get/status',
      data: data, headers: {
        "Content-Type": "application/json",
        "x-reference-id": uuid

      }
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

const getBankList = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: 'api/v1/get/banks'
    }).then(resp => {
      resolve(resp);
    }).catch(error => {
      reject(error.response.data);
    });
  });

const enhanceFetchCredit = (uuid) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: '/api/v1/credit/report/fetch',
      headers: {
        'x-reference-id': uuid
      }
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error);
      });
  });


const fetchFetchExperian = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: '/api/v1/experian/fetch/consent',
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error);
      });
  });

const createConsentExperian = (param) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      data: param,
      url: '/api/v1/experian/consent/create',
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error);
      });
  });


const updateExperianConsent = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: '/api/v1/experian/update/consent',
      headers: {
        'x-experianTncAccepted': 'YES'
      }
    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error);
      });
  });

const revokeConsentApi = () =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'GET',
      url: '/api/v1/experian/consent/revoke',

    }).then(resp => {
      resolve(resp);
    })
      .catch(error => {
        reject(error);
      });
  });


export {
  creditReport,
  boostScoreReport,
  scoreByUploadStatement,
  creditReportService,
  refreshToken,
  reportDownload,
  createDisputeData,
  getDispute,
  getFipTSPUrl,
  getFiuStatus,
  downloadExcelReport,
  getBankList,
  uploadGenDocument,
  enhanceFetchCredit,
  fetchFetchExperian,
  updateExperianConsent,
  createConsentExperian,
  revokeConsentApi,
  getDisputeData
}
