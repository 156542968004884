import { Fragment, useEffect, useState } from 'react';
import './experian-model.css';
import '../../Sign Up/otp/otp.css';
import getUserData from '../../../Common/helper';
import { resendOTPAction } from '../../../redux/actions/auth_action';
import { Loader } from 'rsuite';
const CloseIC = require("../../../images/close.png");;
const ExperianLogo = require("../../../images/Bank/experian.png");
const FiuSuccess = require("../../../images/assets/fiu_success.png");
const FiuError = require("../../../images/assets/fiu_error.png");
const Clock = require("../../../images/assets/clock.png");

function ExperianModal({ onClose, otpRefId, onClickExpFetch, showTypeContent, msgErrorSuccess, serverErrorFromPrev }) {
    const [userData, setUserData] = useState();
    const [isShowMore, setIsShowMore] = useState(false);
    const [isAcceptTerm, setIsAcceptTerm] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [serverError, setServerError] = useState();
    const [otp, setOtp] = useState("");
    const [showError, setShowError] = useState(false);
    const [disableResendBtn, setDisableResendBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    const [otpReferenceId, setOtpReferenceId] = useState(null);

    const acceptTermConditon = (event) => {
        setIsAcceptTerm(event.target.checked);
    }
    const readLessMorestyle = {
        WebkitLineClamp: isShowMore ? "6" : "3",
    };
    const btnEnableDisable = {
        opacity: !isAcceptTerm
            ? 0.5 : 1.0,
    };
    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    useEffect(() => {
        const data = getUserData();
        setUserData(data);
        setOtpReferenceId(otpRefId)
    }, [])

    const resendOTPDisable = {
        opacity: disableResendBtn ? 0.5 : 1.0,
    };


    useEffect(() => {
       
        setServerError(serverErrorFromPrev)
        const interval = setInterval(() => {
            if (seconds > 0) {
                if (seconds < 10) {
                    setSeconds(seconds.toString().padStart(2, "0"));
                }
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {

        }
    };

    const resendOTPApi = () => {

        const param = {
            "mobileNumber": userData.preferred_username,
            "otpReferenceId": otpReferenceId
        };
        setLoader(true)
        resendOTPAction(param).then(resp => {
            setLoader(false)
            if (resp && resp.statusCode === "OTP_SENT") {
                setSeconds(60);
                setOtpReferenceId(resp.otpReferenceId);
            }
            //setResendOtp("Resend OTP")
        }).catch(error => {
            setLoader(false)

            if (error && error?.response && error?.response.data &&
                error?.response.data.errorCode === "RESEND_OTP_ATTEMPT_EXCEEDED") {
                //// DISABLE BUTTON ONLY RESEND
                setDisableResendBtn(true);
            }

            if (error && error?.response && error?.response.data &&
                error?.response.data.errorCode === "FREQUENT_RESEND") {
                setSeconds(60);
                setDisableResendBtn(false);
            }

            /// SHOWING GENERAL MSG ON ERROR SCENARIO
            // if (error && error?.response && error?.response.data &&
            //     error?.response.data.errorCode != "FREQUENT_RESEND") {
            //     setUserExists(error?.response.data.errorMessage);
            // } else {
            //     if (error?.response.data.errorCode != "FREQUENT_RESEND") {
            //         setUserExists("Something went wrong!");
            //     }
            // }
        })
    }

    const expContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center'>Fetch Credit Report</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 model-exp-sub-title text-center'>
            Consent expired or revoked. Enter OTP sent to +91 {userData === undefined ? '' : userData.preferred_username ?? ''} to get latest Credit Report
        </p>
        <div className='my-2'></div>



        <div className="m-0 p-0 w-100">
            <label className="frm-lable mb-2" style={{ fontWeight: '700' }}>Enter OTP</label>
            <input
                type="text"
                className="form-control frm-inpt"
                id="otp"
                aria-describedby="nameInp"
                minLength="6"
                maxLength="6"
                autoComplete="off"
                value={otp}
                pattern="\d{3}-\d{3}-\d{4}"
                onChange={(e) => {
                    setOtp(
                        (e.target.value = e.target.value.replace(
                            /[^0-9-]/g,
                            ""
                        ))
                    );
                }}
                onKeyDown={handleKeyDown}
            />
            {showError && (!otp || otp === "") && (
                <div id="nameInp" className="form-text error-txt">
                    Please enter your otp
                </div>
            )}
            {serverError && serverError !== "" && (
                <div id="nameInp" className="form-text error-txt p-0 m-0 pt-2" style={{ color: '#ff0000' }}>
                    {serverError}
                </div>
            )}
            {seconds == 0 && (
                <div className="mb-1 text-center">
                    <p className="otp-receive m-0 p-0 pt-2">Didn’t receive OTP?</p>
                    <span
                        className="btn-resend m-0 p-0"
                        style={resendOTPDisable}
                        onClick={() => {
                            if (!disableResendBtn) {
                                resendOTPApi()
                            }
                        }}
                    >Resend
                    </span>
                </div>
            )}
        </div>

        {seconds !== 0 && (
            <div className="mb-3 text-center mt-3">
                <p className="frm-lable">
                    <img src={Clock} className="mb-1 img-clock" />{" "}
                    <span className="remainTime">00:{seconds > 9 ? `${seconds}` : `0${seconds}`}</span>
                </p>
            </div>
        )}

        <Fragment>
            <div className="modal-term-content d-flex flex-row align-items-start pt-2">
                <input
                    className='otp-checkbox'
                    type="checkbox"
                    id="flexCheckDefault"
                    value={isAcceptTerm}
                    onChange={acceptTermConditon}
                />
                <div className='ps-2 pt-0'>
                    <label
                        className="experian-term-line"
                        style={readLessMorestyle}
                    >
                        I hereby consent to Boost.Money being appointed as my
                        authorized representative to receive my Credit Information
                        from Experian for the purpose of Credit Assessment to advise
                        me on the financial journey, on ongoing basis for not
                        exceeding a period of six (6) months.
                    </label>
                    <label className="term-txt-otp">
                        I also agree to{" "}
                        <a href="/experian_term">
                            Experian’s Terms and Conditions.
                        </a>
                    </label>

                    <div
                        className="read-more pt-1"
                        onClick={toggleReadMoreLess}>
                        {isShowMore ? "Read less" : "Read more"}
                    </div>
                </div>
            </div>
        </Fragment>

        <div className='my-3'></div>
        <div className='model-fetch-exp-btn text-center'
            style={btnEnableDisable} onClick={isAcceptTerm ? () => onClickExpFetch(otp, otpReferenceId) : null}>Fetch Credit Report</div>
        <div className='my-2'></div>
        <img className="experian-logo-model-mini" src={ExperianLogo} onClick={onClose} />

    </div>);

    const successExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-green'>Congratulations!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuSuccess} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onClose();
        }}>Done</div>
    </div>)

    const failExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-red'>Something went wrong!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuError} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onClose();
        }}>Done</div>
    </div>)


    return (
        <div className="modal-overlay" onClick={onClose}>
            {loader && <Loader />}
            <div className="modal-content-experian bg-white" onClick={e => e.stopPropagation()}>
                <img className="modal-close" src={CloseIC} onClick={onClose} />
                <div className='mt-4'></div>

                {showTypeContent == 'EXPERIAN' && expContent}
                {showTypeContent == 'SUCCESS' && successExpContent}
                {showTypeContent == 'ERROR' && failExpContent}

            </div>
        </div>
    );
}
export default ExperianModal;